<template>
  <div class="container">
    <div class="grid justify-content-center">
      <div class="col-12 md:col-6">
        <div class="mb-2">
          <router-link to="../">
            <Button
              label="Back"
              icon="pi pi-arrow-left"
              class="p-button-sm p-button-primary p-button-outlined p-button-rounded"
            />
          </router-link>
        </div>
        <div class="card nice-shadow-6">
          <div class="mb-4 mt-3">
            <div class="grid align-items-center justify-content-between">
              <div class="pl-2">
                <div class="text-2xl font-bold">OTP Verification</div>
                <div class="text-primary">OTP has been sent to your email</div>
              </div>
              <div class="col-3 text-right">
                <div class="text-2xl font-bold text-info">{{ countDown }}</div>
              </div>
            </div>
          </div>
          <form
            ref="observer"
            tag="form"
            @submit.prevent="startOtpValidation()"
          >
            <div class="grid justify-content-between">
              <div class="col-12 sm:col-8 md:col-9 lg:col-9">
                <InputText
                  class="w-full text-center otp-input"
                  placeholder="Enter Code"
                  v-model="otpCode"
                  required="required"
                  type="text"
                />
              </div>
              <div class="col-12 sm:col-4 md:col-3 lg:col-3">
                <Button
                  class="p-button-md w-full py-3"
                  :loading="loading"
                  type="submit"
                  label="Verify"
                />
              </div>
            </div>
            <transition
              appear
              enter-active-class="animated bounceIn"
              leave-active-class="animated fadeOut"
            >
              <Message
                severity="error"
                v-if="errorMsg"
                closabel
                @close="errorMsg = ''"
              >
                {{ errorMsg.toString() }}
              </Message>
            </transition>
            <div class="flex align-items-center">
              <div class="text-sm text-500">Didn't receive OTP ?</div>
              <div>
                <Button
                  class="p-button-text"
                  :disabled="!canResend"
                  :loading="resending"
                  @click="resendOtp()"
                  type="button"
                  label="Resend"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { PageMixin } from "../../mixins/page.js";
export default {
  props: {},
  mixins: [PageMixin],
  data: function () {
    return {
      otpCode: "",
      loading: false,
      resending: false,
      canResend: false,
      errorMsg: "",
      countDown: "00:00",
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async startOtpValidation() {
      if (this.otpCode) {
        this.loading = true;
        this.errorMsg = "";
        let formData = {
          otp_code: this.otpCode,
          token: this.$route.query.token,
        };
        let rememberUser = false;
        let url = "/auth/validateotp";
        let payload = {
          formData,
          rememberUser,
          url,
        };
        this.login(payload).then(
          (response) => {
            this.loading = false;
            let loginData = response.data;
            if (loginData.token) {
              window.location = "/"; //user is now logged in. Navigate to home page
            } else {
              this.$router.push(loginData.nextpage);
            }
          },
          (request) => {
            this.loading = false;
            this.errorMsg = request.response.data || "Unable to validate otp";
          }
        );
      }
    },
    async resendOtp() {
      this.resending = true;
      this.errorMsg = "";
      let formData = {
        token: this.$route.query.token,
      };
      let url = "/auth/resendotp";
      this.$api.post(url, formData).then(
        (response) => {
          this.resending = false;
          this.canResend = false;
          this.flashMsg("OTP Sent Successfully");
          this.startCountDown();
        },
        (request) => {
          this.resending = false;
          this.errorMsg = request.response.data || "Unable to resend otp";
        }
      );
    },
    startCountDown() {
      let duration = parseInt(this.$route.query.duration) || 5; // in minutes
      let minutes = 60 * duration;
      let timer = minutes;
      let seconds;
      var self = this;
      const interval = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        self.countDown = minutes + ":" + seconds;
        if (--timer < 0) {
          clearInterval(interval);
          self.errorMsg = "OTP has expired";
          self.canResend = true;
        }
      }, 1000);
    },
  },
  mounted() {
    this.startCountDown();
  },
};
</script>
<style>
.otp-input {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  letter-spacing: 10px;
}
.otp-input::placeholder {
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
}
</style>
